import { isBrowser } from '../isBrowser';

const nonPartnerPages = [
  'add-buy-sell-trades-manually',
  'contract-notes',
  'common-trade-confirmation-email-errors',
];

export const showPartnerLogo = () => {
  if (!isBrowser) return false;

  return !nonPartnerPages.some(path => window.location.pathname.includes(path));
};
