import { showPartnerLogo } from 'helpers/partners/showPartnerLogo';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container } from 'components/Container';

import { BlogPostContainer, ContainerInner } from 'components/Blog';
import { AnimatedContainer } from 'components/Container/AnimatedContainer';

import { useScript } from 'hooks/useScript';
import { BackButton, PartnerLogo, PartnerSideMenu } from 'components/Partners';
import { Accordion } from 'components/Accordion';
import { StyledHtml } from 'components/StyledHtml';

import { Box } from 'components/Box';

import type { FC } from 'react';
import type { Partner } from 'types/partners/partner';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const IndividualPartner: FC<
  LocalizedPageProps & {
    pageContext: {
      partner: Partner;
    };
  }
> = ({ pageContext: { partner } }) => {
  useScript('https://cdn.embedly.com/widgets/platform.js');

  const content = partner.content;

  const showLogo = showPartnerLogo();

  return (
    <Layout hideFooterCta>
      <>
        <Seo />
        <AnimatedContainer manageLayout={false}>
          <BackButton
            asButton
            to="/partners"
            title="Brokers & Apps | Sharesight"
            appearance="tertiary"
            showArrowIcon
            iconDirection="left"
          >
            Back to brokers & apps
          </BackButton>
          <Container verticalAlign="center" color="transparent" gap={15} scaleMobileGap padding={0}>
            <ContainerInner>
              <div>
                {showLogo && (
                  <Box marginBottom={3} marginTop={3}>
                    <PartnerLogo partner={partner} />
                  </Box>
                )}
                <h1>{partner.title}</h1>
              </div>
              {!partner.hideOnIndex && <PartnerSideMenu partner={partner} />}
            </ContainerInner>
          </Container>
        </AnimatedContainer>
        <Container
          padding={10}
          bottomPadding={15}
          verticalAlign="top"
          color="white"
          manageLayout={false}
        >
          <ContainerInner>
            <BlogPostContainer padding={0} gap={2} manageLayout={false}>
              <StyledHtml html={content} />

              {/* NOTE: at least one partner needs to have the accordionHeader and accordionItems fields, otherwise it's possible that builds will fail */}
              {partner.accordionHeader && partner.accordionItems && (
                <Accordion
                  title={partner.accordionHeader}
                  details={partner.accordionItems}
                  padding={0}
                />
              )}
            </BlogPostContainer>
          </ContainerInner>
        </Container>
      </>
    </Layout>
  );
};
export default IndividualPartner;
